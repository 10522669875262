import React from 'react';
import { IconButton, IconButtonPage, Layout } from '../../components/onboarding';
import ACCEPTED_OFFER from '../../images/onboarding/accepted-offer.svg';
import OPEN_HOUSE from '../../images/onboarding/open-house.svg';
import RESEARCH from '../../images/onboarding/research.svg';
import { my } from '../../modules/navigation';

const title = 'How exciting! Where in the home purchasing process are you?';

const Buying = () => (
  <Layout title={title}>
    <IconButtonPage title={title}>
      <IconButton
        icon={ACCEPTED_OFFER}
        text="I have an accepted offer"
        trackingEvent="discover-buying-ao-clicked"
        href={`${my}/onboarding/accepted-offer`}
      />
      <IconButton
        icon={OPEN_HOUSE}
        text="I'm going to open houses"
        href="/onboarding/preapproved"
        trackingEvent="discover-buying-oh-clicked"
      />
      <IconButton
        icon={RESEARCH}
        text="I'm doing research"
        href="/onboarding/preapproved"
        trackingEvent="discover-buying-research-clicked"
      />
    </IconButtonPage>
  </Layout>
);

export default Buying;
